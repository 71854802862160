export const Toast = Swal.mixin({
    position: 'bottom-right',
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 5000,
    grow: 'row',
    toast: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

export var audio = new Audio(Flask.url_for('static', {"filename": 'img/Beep-sound-effect.mp3'}));

export function ajaxError(id, msg, error, timer = 3000, icon = 'warning', aud = false) {
    if (id === 400) {
        Swal.fire({
            title: msg.responseJSON['message'],
            icon: icon,
            timer: timer,
            allowEnterKey: true,
            showConfirmButton: true,

            timerProgressBar: true,
            returnFocus: false
        });
        if (aud) {
            audio.play();
        }
    } else if (id === 0) {
        Swal.fire({
            title: _('Connection lost to the server, try again later!'),
            html:
                _('Please contact technical support if the problem persists.'),
            icon: 'error',
            allowEnterKey: true,
            showConfirmButton: true,
        });
        audio.play();
    } else {
        Swal.fire({
            title: error,
            icon: 'error',
            allowEnterKey: true,
            showConfirmButton: true
        });
        audio.play();
    }
}

$('.table').on('draw.dt', function () {
    $('[data-mdb-toggle="tooltip"]').tooltip();
    $('[data-mdb-toggle="popover"]').popover();
});

$('.locale').on('click', function () {
    $.ajax({
        url: Flask.url_for("view.change_locale"),
        dataType: 'json',
        type: 'POST',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify({
            "locale": $(this).data('locale'),
        }),
        complete: function () {
            location.reload(true);
        }
    });

});

export function strtrunc(str, max, add) {
    add = add || '...';
    return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
}

export function inputcheck(input, sound = false) {
    if (!input.checkValidity()) {
        $(input).addClass('is-invalid').prop('disabled', false).focus();
        if (sound) {
            audio.play();
        }
        return false;
    } else {
        $(input).removeClass('is-invalid');
        return true;
    }
}

export var enter = $.Event("keypress", {which: 13});

export var dte_i18n = {
    "close": "Zatvoriť",
    "create": {
        "button": "Nový",
        "title": "Vytvorenie nového záznamu",
        "submit": "Vytvoriť"
    },
    "edit": {
        "button": "Upraviť",
        "title": "Upraviť záznam",
        "submit": "Upraviť"
    },
    "remove": {
        "button": "Odstrániť",
        "title": "Odstrániť záznam",
        "submit": "Odstrániť",
        "confirm": {
            "_": "Naozaj chcete zmazať %d záznamy / záznamov?",
            "1": "Naozaj chcete zmazať jeden záznam?"
        }
    },
    "error": {
        "system": "Vyskytla sa systémová chyba (viac informácií)"
    },
    "multi": {
        "title": "Viaceré hodnoty",
        "info": "Vybraté položky obsahujú rôzne hodnoty pre tento vstup. Ak chcete upraviť a nastaviť všetky položky pre tento vstup na rovnakú hodnotu, kliknite alebo klepnite sem, inak si zachovajú svoje pôvodné hodnoty.",
        "restore": "Vrátiť zmeny",
        "noMulti": "Tento vstup je možné upravovať len jednotlivo, nie skupinovo."
    },
    "datetime": {
        "previous": 'Predchádzajúci',
        "next": 'Nasledujúci',
        "months": [
            'Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'
        ],
        "weekdays": ['N', 'Po', 'U', 'S', 'Š', 'Pi', 'S'
        ],
        "amPm": ['am', 'pm'
        ],
        "unknown": '-'
    }
};

export var DT_error = function (jqXHR, exception, error) {
    "use strict";
    console.log('Error status: ' + jqXHR.status);
    console.log('Exception: ' + exception);
    console.log('Error message: ' + error);
    if (jqXHR.status === 403) {
        location.reload();
    }
    else if (jqXHR.status !== 0) {
        throw new Error('Error message: ' + error + '\nException: ' + exception + '\nError message: ' + error);
    }

};

!(function ($) {
    "use strict";
    $('.has-spinner').attr("disabled", false);
    $.fn.buttonLoader = function (action, text_value = _('Loading')) {
        var self = $(this);
        var text = text_value;
        if (action === 'start') {
            if ($(self).attr("disabled") === "disabled") {
                return false;
            }
            $('.has-spinner').attr("disabled", true);
            $(self).attr('data-btn-text', $(self).text());
            if ($(self).attr('data-load-text') !== undefined && $(self).attr('data-load-text') !== "") {
                text = $(self).attr('data-load-text');
            }
            $(self).html('<span class="spinner"><i class="fa fa-spinner fa-spin" title="button-loader"></i></span> ' + text);
            $(self).addClass('active');
        }
        if (action === 'stop') {
            $(self).html($(self).attr('data-btn-text'));
            $(self).removeClass('active');
            $('.has-spinner').attr("disabled", false);
        }
    };
})(jQuery);
