$.fn.dataTable.Buttons.defaults.dom.button.className = "btn btn-info";
$.fn.dataTable.Editor.bootstrap( mdb );

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';

import JSZip from 'jszip';

window.JSZip = JSZip;
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.css';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-datetime/js/dataTables.dateTime';
import 'datatables.net-datetime/css/dataTables.dateTime.scss';
import 'datatables.net-colreorder-bs5';
import 'datatables.net-colreorder-bs5/css/colReorder.bootstrap5.css';
// import 'datatables.net-fixedcolumns-bs5';
// import 'datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.css';
import 'datatables.net-fixedheader-bs5';
// import 'datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5.css';
import 'datatables.net-rowgroup-bs5';
import 'datatables.net-rowgroup-bs5/css/rowGroup.bootstrap5.css';
// import 'datatables.net-rowreorder-bs5';
// import 'datatables.net-rowreorder-bs5/css/rowReorder.bootstrap5.css';
import 'datatables.net-responsive-bs5';
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.css';
import 'datatables.net-scroller-bs5';
// import 'datatables.net-scroller-bs5/css/scroller.bootstrap5.css';
import 'datatables.net-select-bs5';
import 'datatables.net-select-bs5/css/select.bootstrap5.css';
import 'datatables.net-editor-bs5';
import 'datatables.net-editor-bs5/css/editor.bootstrap5.css';
// import 'datatables.net-searchpanes-bs5';
// import 'datatables.net-searchpanes-bs5/css/searchPanes.bootstrap5.css';
// import 'datatables.net-searchbuilder-bs5';
// import 'datatables.net-searchbuilder-bs5/css/searchBuilder.bootstrap5.css';
import moment from 'moment';

window.moment = moment;
import DataTable from 'datatables.net';
window.DataTable = DataTable;
import 'datatables.net-plugins/sorting/datetime-moment.js';
import 'datatables.net-plugins/dataRender/datetime.js';

import * as Sentry from "@sentry/browser";
import Holidays from 'date-holidays';

window.Holidays = Holidays;

window.Sentry = Sentry;

import SentryRRWeb from '@sentry/rrweb';

window.SentryRRWeb = SentryRRWeb;

import Swal from 'sweetalert2/dist/sweetalert2.js'

window.Swal = window.swal = Swal;

import 'daterangepicker';
import "daterangepicker/daterangepicker.css";

// window.Noty = require('noty');
//
// import {io} from "socket.io-client";
//
// window.io = io;

import * as mdb from 'mdb-ui-kit'; // lib
window.mdb = mdb; // add lib as a global object

import { LocalStorage } from 'storage-manager';
window.LocalStorage = LocalStorage // add plugin as a global object

import { Dropzone } from "dropzone";
window.dropzone = Dropzone;

import {Toast, DT_error, inputcheck, ajaxError, strtrunc, dte_i18n, enter} from './custom.js';

window.Toast = Toast;
window.DT_error = DT_error;
window.inputcheck = inputcheck;
window.ajaxError = ajaxError;
window.strtrunc = strtrunc;
window.dte_i18n = dte_i18n;
window.enter = enter

// import * as select2 from 'select2';
//
// window.select2 = select2; // add lib as a global object

import yadcf from 'yadcf';
yadcf.c
window.yadcf = yadcf;


import "js-storage";

// import * as IdleTimeoutPlus from "./jquery-idleTimeout-plus.js";
//
// window.IdleTimeoutPlus = IdleTimeoutPlus;
